// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/homeBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".home .home-background {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  height: calc(100vh - 60px);\n  width: 100%;\n  position: relative;\n  /*background-color: #fff*/\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% 50%;\n  background-size: 100% 100%;\n}\n", ""]);
// Exports
module.exports = exports;
