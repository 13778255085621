var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      { staticClass: "home" },
      [
        _c("row", [
          _c(
            "div",
            { staticClass: "home-background", attrs: { id: "navigation" } },
            [
              _vm.processShow == 0 ? _c("homeCard") : _vm._e(),
              _vm.processShow == 1 ? _c("process") : _vm._e(),
              _vm.processShow == 2 ? _c("process-healthy") : _vm._e(),
              _vm.processShow == 3 ? _c("process-practice") : _vm._e(),
              _vm.processShow == 4 ? _c("process-radiation") : _vm._e(),
              _vm.processShow == 7 ? _c("process-finance") : _vm._e(),
              _vm.processShow != 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        "margin-left": "12px",
                        "margin-top": "12px",
                      },
                    },
                    [
                      _vm.processShow == 1
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: "/big_screen/images/职业体检.png" },
                          })
                        : _vm._e(),
                      _vm.processShow == 2
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: "/big_screen/images/健康体检.png" },
                          })
                        : _vm._e(),
                      _vm.processShow == 3
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: "/big_screen/images/从业体检.png" },
                          })
                        : _vm._e(),
                      _vm.processShow == 4
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: "/big_screen/images/放射体检.png" },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "15px",
                    color: "#837e7e",
                    position: "absolute",
                    right: "24px",
                    bottom: "18px",
                    "font-weight": "500",
                  },
                },
                [_vm._v("   体检管理系统" + _vm._s(_vm.softwareVersion) + " ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }